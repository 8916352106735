import React, { SVGProps } from 'react';

export default function BookdocLogo(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 86.000000 80.000000"
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <g transform="translate(0.000000,80.000000) scale(0.100000,-0.100000)" fill="#f22f46" stroke="none">
        <path d="M470 765 c0 -8 -3 -14 -7 -13 -5 0 -15 -2 -23 -5 -8 -4 -53 -8 -100 -9 l-85 -3 0 -142 c0 -132 -1 -141 -17 -126 -37 34 -131 8 -159 -44 -7 -13 -13 -39 -13 -58 0 -27 -4 -35 -20 -37 -12 -2 -19 -7 -16 -13 3 -5 15 -9 27 -7 14 2 24 -4 28 -16 6 -21 110 -126 184 -187 87 -72 130 -80 224 -44 l54 21 31 -31 c42 -42 92 -43 133 -2 16 16 29 36 29 44 0 8 7 17 15 21 13 5 15 46 13 313 l-3 308 -105 6 c-124 8 -114 5 -106 24 4 12 -4 15 -39 15 -33 0 -45 -4 -45 -15z m50 -15 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m-22 -22 c8 -8 15 -8 26 1 11 9 20 10 36 1 33 -18 13 -30 -51 -30 -44 0 -59 4 -59 14 0 12 12 23 31 25 3 1 10 -4 17 -11z m-64 -43 c4 -11 22 -15 76 -15 54 0 72 4 76 15 8 19 124 21 142 3 9 -9 12 -83 12 -272 l0 -260 -29 28 c-45 43 -124 36 -151 -14 -10 -18 -21 -20 -132 -20 -99 0 -125 -3 -140 -17 -10 -9 -18 -12 -18 -6 0 5 21 24 48 40 146 96 189 204 111 279 -26 25 -38 29 -85 29 l-54 0 0 106 c0 58 3 109 7 112 3 4 34 7 69 7 46 0 63 -4 68 -15z m-198 -244 c32 -19 32 -19 72 0 30 15 45 17 65 10 63 -24 85 -92 50 -153 -10 -16 -51 -56 -91 -89 l-73 -60 -52 47 c-57 52 -97 95 -97 106 0 4 16 7 35 6 22 -1 35 -6 35 -15 0 -18 19 -17 26 2 4 8 12 15 20 15 9 0 14 -16 16 -50 4 -65 20 -68 33 -7 8 38 13 45 23 35 9 -9 12 -8 12 6 0 14 5 16 30 11 23 -5 30 -3 30 9 0 11 -10 16 -34 16 -21 0 -36 6 -40 15 -9 26 -26 17 -26 -14 0 -53 -20 -45 -21 8 -2 71 -3 74 -16 79 -9 2 -13 -8 -13 -33 0 -20 -7 -43 -15 -51 -12 -13 -16 -12 -26 7 -10 20 -12 20 -21 6 -5 -10 -21 -17 -38 -17 -27 0 -30 3 -30 33 1 49 47 96 95 97 11 0 34 -9 51 -19z m451 -271 c43 -39 38 -83 -12 -116 -28 -18 -57 -12 -85 19 -27 28 -25 63 5 92 29 30 64 32 92 5z m-209 -86 l-77 -26 -53 25 -53 25 130 1 130 0 -77 -25z" />
        <path d="M372 594 c3 -13 24 -16 146 -17 129 -2 142 0 142 16 0 15 -14 17 -146 17 -128 0 -145 -2 -142 -16z" />
        <path d="M372 524 c3 -13 24 -16 146 -17 129 -2 142 0 142 16 0 15 -14 17 -146 17 -128 0 -145 -2 -142 -16z" />
        <path d="M444 448 c9 -15 26 -18 108 -18 87 0 98 2 98 18 0 15 -12 17 -109 17 -100 0 -107 -1 -97 -17z" />
        <path d="M480 378 c0 -16 11 -18 86 -18 74 0 85 2 82 16 -2 14 -18 17 -86 18 -72 1 -82 -1 -82 -16z" />
        <path d="M616 141 c-21 -23 -16 -45 13 -61 17 -8 25 -6 42 11 18 18 19 23 6 42 -19 29 -39 32 -61 8z m39 -21 c-3 -5 -11 -10 -16 -10 -6 0 -7 5 -4 10 3 6 11 10 16 10 6 0 7 -4 4 -10z" />
      </g>
    </svg>
  );
}
