import React from 'react';

export default function VideoLogo() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
      <path d="M24 24H0V0h24v24z" fill="none" />
      <circle cx="12" cy="12" r="8" fill="#F22F46" />
    </svg>
  );
}
